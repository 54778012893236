export async function initFormKit() {
  const nuxtApp = useNuxtApp()
  if (nuxtApp.__formkit_loaded) {
    return
  }
  nuxtApp.__formkit_loaded = true
  const { plugin, config, resetCount, ssrComplete } = await import(
    './../helpers/formkitLoader'
  )
  nuxtApp.hook('app:rendered', () => {
    resetCount()
    ssrComplete(nuxtApp.vueApp)
  })
  nuxtApp.vueApp.use(plugin, config)
}
